<template>
  <div v-html="dom"></div>
</template>

<script>
</script>
<script>
export default {
  name: "zf",
  data() {
    return {
      dom: "",
    };
  },
  mounted() {
    this.dom = localStorage.getItem("dom");
    this.$nextTick(() => {
      console.log("支付宝页面")
      document.forms[0].submit();
    });
  },
};
</script>

<style>
</style>